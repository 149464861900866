import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import Hakijatkaup from './components/Hakijatkaup';


const HeroSection = lazy(() => import('./components/HeroSection'));
const Introduction = lazy(() => import('./components/Introduction'));
const BlogSection = lazy(() => import('./components/BlogSection'));
const Hero2 = lazy(() => import('./components/Hero2'));
const Process = lazy(() => import('./components/Process'));
const JobListings = lazy(() => import('./components/JobListings'));
const OtaYhteytta = lazy(() => import('./components/OtaYhteytta'));
const Meista = lazy(() => import('./components/Meista'));
const Myynti = lazy(() => import('./components/Myynti'));
const Hakemus = lazy(() => import('./components/Hakemus'));
const Hakijatit = lazy(() => import('./components/Hakijatit'));
const Asiantuntijarekry = lazy(() => import('./components/Asiantuntijarekry'));
const YrityksilleOtaYhteytta = lazy(() => import('./components/YrityksilleOtaYhteytta'));
const Tietosuojaseloste = lazy(() => import('./components/tietosuojaseloste'));
const BlogiSuora = lazy(() => import('./components/blogiSuora'));
const BlogiTko = lazy(() => import('./components/blogiTko'));
const BlogiEtatyo = lazy(() => import('./components/blogiEtatyo'));
const BlogiTulevaisuudentaidot = lazy(() => import('./components/blogiTulevaisuudentaidot'));
const Yhteytta = lazy(() => import('./components/yhteytta'));
const EvasteKaytanto = lazy(() => import('./components/evastekaytanto'));
const Henkiloarviointi = lazy(() => import('./components/henkiloarviointi'));
const Suorahaku = lazy(() => import('./components/suorahaku'));
const Tyopaikka1 = lazy(() => import('./components/Tyopaikka1'));
const ApplyJobPage = lazy(() => import('./components/ApplyJobPage'));

function HomePage() {
  return (
    <>
      <HeroSection />
      <Introduction />
      <Hero2 />
      <Process />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/yrityksille" element={<YrityksilleOtaYhteytta />} />
            <Route path="/asiantuntijoidenrekrytointi" element={<Asiantuntijarekry />} />
            <Route path="/myynti-alan-rekrytointi" element={<Myynti />} />
            <Route path="/avoin-hakemus" element={<Hakemus />} />
            <Route path="/avoimet-tyopaikat" element={<JobListings />} />
            <Route path="/ota-yhteytta" element={<OtaYhteytta />} />
            <Route path="/meista" element={<Meista />} />
            <Route path="/it-alan-rekrytointi" element={<Hakijatit />} />
            <Route path="/tietosuojaseloste" element={<Tietosuojaseloste />} />
            <Route path="/hybridihaku" element={<BlogiSuora />} />
            <Route path="/tekoaly-tyonhaku" element={<BlogiTko />} />
            <Route path="/etatyon-vaikutus" element={<BlogiEtatyo />} />
            <Route path="/tulevaisuuden-taidot" element={<BlogiTulevaisuudentaidot />} />
            <Route path="/kaupallisen-alan-rekrytointi" element={<Hakijatkaup />} />
            <Route path="/yhteytta" element={<Yhteytta />} />
            <Route path="/evasteet" element={<EvasteKaytanto />} />
            <Route path="/henkiloarviointi" element={<Henkiloarviointi />} />
            <Route path="/suorahaku" element={<Suorahaku />} />
            <Route path="/toimitusjohtaja-sisutaxi" element={<Tyopaikka1 />} />
            <Route path="/hae-tyopaikkaa" element={<ApplyJobPage />} />






            

          </Routes>
        </Suspense>
        <CookieConsent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
